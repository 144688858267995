var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-light-grey text-left mb-2" },
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var validated = ref.validated
                var passed = ref.passed
                var failed = ref.failed
                var errors = ref.errors
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-12 gap-x-6 p-2 border-dashed border-b border-brown-grey",
                    },
                    [
                      _c("div", { staticClass: "col-start-1 col-span-10" }, [
                        _vm.hasUnfilled(errors)
                          ? _c("img", {
                              staticClass: "w-6 h-6 float-left",
                              attrs: {
                                src: require("@/assets/image/alert_icon.svg"),
                              },
                            })
                          : validated && passed
                          ? _c("img", {
                              staticClass: "w-6 h-6 float-left",
                              attrs: { src: _vm.okIcon[_vm.$i18n.locale] },
                            })
                          : validated && failed
                          ? _c("img", {
                              staticClass: "w-6 h-6 float-left",
                              attrs: { src: _vm.ngIcon[_vm.$i18n.locale] },
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "ml-8" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-span-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "h-6 w-6 float-right",
                            class: validated && passed ? "cursor-pointer" : "",
                            on: {
                              click: function ($event) {
                                validated && passed ? _vm.toggleState() : null
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "h-6 w-6 inline transition transform duration-200",
                                class: _vm.isOpen ? "rotate-180" : "",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  stroke: "currentColor",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    d: "M19 9l-7 7-7-7",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm.isOpen || (validated && failed) || !validated
                    ? [
                        _c(
                          "div",
                          { staticClass: "p-2" },
                          [_vm._t("default")],
                          2
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }