var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _vm.label && _vm.label.length
      ? _c("span", [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _c("div", { staticClass: "w-full relative" }, [
      _c(
        "div",
        { staticClass: "absolute inset-0 rounded grid grid-cols-11 gap-x-6" },
        [
          _c("div", {
            staticClass: "col-start-0 col-span-5 bg-dark-grey h-full rounded",
          }),
          _c(
            "div",
            {
              staticClass:
                "col-start-6 col-span-1 row-span-full flex flex-col justify-center",
            },
            [
              _vm.showControls && _vm.showRightSide
                ? [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "px-4 py-2",
                              on: { click: _vm.copyFields },
                            },
                            [_vm._v("agree")]
                          ),
                        ]
                      ),
                    ]),
                  ]
                : [
                    _vm.showRightSide
                      ? _c("div", { staticClass: "text-center mx-auto" }, [
                          _c("div", { staticClass: "right-arrow" }),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          ),
          _vm.showRightSide
            ? _c(
                "div",
                {
                  staticClass:
                    "col-start-7 col-span-5 bg-white h-full rounded relative",
                },
                [
                  _vm.showCheckbox && !_vm.value
                    ? [
                        _c(
                          "span",
                          {
                            staticClass:
                              "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-red-600",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("pages.commons.quotes.unable_to_provide")
                              )
                            ),
                          ]
                        ),
                        _vm.showWarning
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-xs absolute bottom-2 text-justify right-4 left-4",
                              },
                              [
                                _vm._v(
                                  "提供不能を選択された場合、この契約は成立しない可能性が高くなります。 有償サービスのご利用を含めて、ご検討ください。"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "w-full" },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full grid grid-cols-11 gap-x-6 relative text-xs font-bold",
            },
            [
              _c("span", { staticClass: "col-start-0 col-span-5 p-2" }, [
                _vm._v(
                  _vm._s(_vm.$t("pages.commons.quotes.customers_request"))
                ),
              ]),
              _vm.showRightSide
                ? _c("span", { staticClass: "col-start-7 col-span-5 p-2" }, [
                    _vm._v(_vm._s(_vm.$t("pages.commons.quotes.ch_response"))),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.showCheckbox && _vm.showRightSide && _vm.isNegotiating
            ? _c("TermsField", {
                attrs: { noCount: true },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "negotiate",
                      fn: function () {
                        return [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.mutableValue,
                                  expression: "mutableValue",
                                },
                              ],
                              staticClass:
                                "rounded-sm w-5 h-5 disabled:bg-brown-grey text-white inline-block relative align-middle",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.mutableValue)
                                  ? _vm._i(_vm.mutableValue, null) > -1
                                  : _vm.mutableValue,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.mutableValue,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.mutableValue = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.mutableValue = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.mutableValue = $$c
                                  }
                                },
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("pages.commons.quotes.able_to_provide")
                                )
                              ),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3840453384
                ),
              })
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }