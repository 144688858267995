var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid grid-cols-11 gap-x-6 relative" }, [
    _c("div", { staticClass: "col-span-5 mx-2 mt-2" }, [_vm._t("original")], 2),
    _c("div", { staticClass: "col-span-1" }),
    _vm.showRightSide
      ? _c(
          "div",
          { staticClass: "col-span-5 mx-2 mt-2" },
          [
            _vm.isNegotiating && _vm.showNegotiation
              ? _vm._t("negotiate", null, { response: _vm.mutableValue })
              : !_vm.isNegotiating
              ? _vm._t("result")
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }